import React from 'react'
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Img from "../../components/image-transform"

const aboutContent = {
  title: "We like to think that we're pretty good at billing.",
  content: <><p className="section-text">After all, we've been doing it a while. Billsby is a product of <a href="https://airfinetworks.com/">Airfi Networks</a> - we've been around for half a decade servicing the subscription billing needs of the Internet of Things industry. We've helped to connect everything from suitcases to watches, and now we're turning our hand to everything else with a refreshing and new approach to subscription management software.</p>
  <p className="section-text">We're bringing the kinds of features big telecommunications firms use to manage their subscription customers to everyone - from local clubs to global brands. So when you choose Billsby, you know you're in safe hands.</p></>
}

const aboutContent2 = {
  title: "We don't make money until you do, so we're in this one together.",
  image:  require('../../images/man_payment.svg'),
  content: [
    {
      heading: "Fairness is core to our values",
      details: "It's not fair to charge you for subscription billing when you're not actually billing anyone, so we won't. There's no minimum monthly fee to use Billsby.",
    },
    {
      heading: "Start with $5,000 for free",
      details: "Once you're ready to start billing customers, we'll waive our fee on your first $5,000 of processing too - helping to grow your business.",
    }, 
    {
      heading: "Doing the right thing for charities and not-for-profits",
      details: "From day one, we've worked to create bespoke commercial deals for large charities and not-for-profits too, to help them bill efficiently.",
    },  
  ]
}

const aboutContent3 = {
  title: "We're easy to get hold of.",
  content: <><p className="section-text">Headquartered in London, UK with offices in San Francisco, CA. You can reach out by emailing <a href="https://airfinetworks.com/">hello@billsby.com</a> or calling us on +1 (855) 934 0707 or +44 (0)800 090 3656. If you're a customer, the quickest way to get hold of us is pressing the little Intercom button in your control panel or the bottom right hand corner of any page (including this one!).</p></>,
  image: 'chat.png',
}

const banner = {
  title: "Hello. We're Billsby.",
  image:  require('../../images/team-on-sofa.svg'),
  alt: "Billsby"
}

const About = () => {
  return(
    <Layout>
      <SEO 
        title="Hello. We're Billsby." 
        description="Learn about how Billsby are revolutionizing subscription billing for small businesses through enterprises globally." 
        url="https://www.billsby.com/company/about"
      />
      <div className="about">
        <div className="section-hero">
          <h1 className="section-heading">{banner.title}</h1>
          <img src={banner.image} alt={banner.alt}/>
        </div>

        <div className="section-about">
          <div className="container">
            <h2 className="section-heading">{aboutContent.title}</h2>
            {aboutContent.content}
          </div>
        </div>

        <div className="section-about-payment">
          <div className="container container-flex">
            <div className="about-content">
              <h2 className="section-heading">{aboutContent2.title}</h2>
              {
                aboutContent2.content.map((item, i) => (
                  <div className="about-info" key={i}>
                    <p className="section-title">{item.heading}</p>
                    <p className="section-text">{item.details}</p>
                  </div>
                ))
              }
            </div>
            <div className="about-img">
              <img src={aboutContent2.image} alt={aboutContent2.heading} />
            </div>
          </div>
        </div>
      
        <div className="section-cta">
          <div className="container">
            <h2 className="section-heading">{aboutContent3.title}</h2>
            {aboutContent3.content}
            <div className="cta-img">
              <Img filename={aboutContent3.image} alt={aboutContent3.title}/>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default About
